import { useEffect } from 'react';

export const useZendesk = () => {
  useEffect(() => {
    if (typeof window.zE == 'function') {
      window.zE('messenger', 'hide');
      window.zE('messenger:on', 'close', function () {
        window.zE('messenger', 'hide');
      });
    }
  });
};

export const Zendesk = () => {
  useZendesk();
  return null;
};
